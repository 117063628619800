import { CardMedia } from '@mui/material';

const LogoName = 'trident.svg';

const logoWidth = '145px';

export const Logo1 = (): JSX.Element => {
  return (
    <CardMedia
      component="img"
      src={`/images/${LogoName}`}
      alt="Logo"
      sx={{ width: logoWidth, mt: 1, ml: 2, pb: 1 }}
    />
  );
};

export const LogoEditApplication = (): JSX.Element => {
  return (
    <CardMedia
      component="img"
      src={`/images/${LogoName}`}
      alt="Logo"
      sx={{ width: logoWidth, m: 2, my: 1 }}
    />
  );
};

export const LogoSideBar = (): JSX.Element => {
  return (
    <CardMedia component="img" src={`/images/${LogoName}`} alt="Logo" sx={{ width: logoWidth }} />
  );
};
