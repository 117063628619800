import { Grid, SelectChangeEvent, Typography } from '@mui/material';
import { ProceedSaveLater } from './components';
import { Formik, validateYupSchema, yupToFormErrors } from 'formik';
import { MFTextField } from '../../lib/formik';
import {
  ApplicationProps,
  JointholderRelationMaster,
  DistributorListType,
} from '../../redux-store/types/api-types';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootStateType } from '../../redux-store/reducers';
import { updateApplication } from '../../redux-store/actions/application';
import { useHistory } from 'react-router';
import { ClassPlanProps, FundProps } from '../../redux-store/types/funds';
import { investmentPaymentDetailsSchema } from '../../utils/schema';
import {
  APPLICATION_TYPE,
  RiskProfileEnabled,
  SAVE_LATER_VALIDATION_CHECK,
  USER_ROLES,
  defaultCurrency,
} from '../../utils/constant';
import {
  allowOnlyNumbers,
  applicationComparison,
  currencyConversion,
  getStep,
  isAccreditedInvestorApplicable,
  isFieldApplicable,
  isFieldVariable,
  saveForLater,
  setUpFeeCalculations,
  statusRoleBased,
  updatedCommitmentAmount,
} from '../../utils/utilityFunctions';
import { useSnackbar } from 'notistack';
import { getDirectApplicableFunds } from '../../redux-store/actions/funds';
import { getDistributorById } from '../../redux-store/actions/userManagement';
import { SearchableSelect } from '../../lib/formik/searchSelectField';
import { jointHolderRelationsList } from '../../redux-store/actions';
import FieldValidationNote from './FieldValidationNote';
import MFCheckbox from '../../lib/formik/Checkbox';
import { ConfirmationDialog } from '../commonComponents';
import { showError } from '../../redux-store/actions/auth';

type Values = Partial<ApplicationProps> & {
  saveType: string;
};

const fundInitialValues = {
  schemeId: '',
  planId: '',
  commitmentAmount: 0,
  setupFeePercentage: 0,
  managementFeesRate: 0,
  taxRate: 0,
  drawDownNumber: 0,
  contributionChequeAmount: 0,
  setupFeeAmount: null,
  setupFeeGST: null,
  totalSetupFee: null,
  setupFeeTDS: null,
  netSetupFeeLessTDS: null,
  totalAmountPayable: null,
  isAccreditedInvestor: false,
  hurdleRateApplicable: '',
  hurdleRate: 0,
  performanceFeeApplicable: '',
  performanceFee: 0,
  exitChargeApplicable: '',
  exitCharge: 0,
  managementFeeApplicable: '',
  exitChargeType: '',
  hurdleRateType: '',
  performanceFeeType: '',
  managementFeeType: '',
  placementFeeApplicable: '',
  placementFeeType: '',
  placementFee: 0,
  operatingExpenseApplicable: '',
  operatingExpenses: 0,
  operatingExpenseType: '',
  setupFeeType: '',
  setupFeeApplicable: '',
  exitLoad: '',
  groupInvestment: false,
};

const initialValues: Values = {
  ...fundInitialValues,
  modeOfPayment: 'cheque',
  paymentRefNo: '',
  chequeDate: '',
  paymentBankAccountNumber: '',
  paymentBankame: '',
  paymentBankAccontType: '',
  saveType: 'save and proceed',
};

export default function InvestmentPaymentDetails(): JSX.Element {
  // const [open, setOpen] = useState(false);
  // eslint-disable-next-line
  const [paymentOpen, setPaymentOpen] = useState(false);
  const [fundsList, setFundsList] = useState<FundProps[]>([]);
  const [selectedFund, setSelectedFund] = useState<FundProps | null>(null);
  const [loading, setLoading] = useState(false);
  const [selectedPlan, setSelectedPlan] = useState<Partial<ClassPlanProps> | null>(null);
  const { application } = useSelector((store: RootStateType) => store.application);
  const { role = '' } = useSelector((store: RootStateType) => store.auth);
  const [paymentDetails, setPaymentDetails] = useState(initialValues);
  const dispatch = useDispatch();
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const [jointHoldersData, setJointHoldersData] = useState<JointholderRelationMaster>();
  const [openDialog, setDialog] = useState<{
    investmentData: Values;
    checkApplication: boolean;
  } | null>(null);

  const {
    minCommitmentAmount = 10000000,
    maxCommitmentAmount = 20000000,
    minContributionPercentage = 10,
    currency = defaultCurrency,
    maxExitCharge = 100,
    maxHurdleRate = 100,
    maxManagementFee = 100,
    maxPerformanceFee = 100,
    minExitCharge = 0,
    minHurdleRate = 0,
    minManagementFee = 0,
    minPerformanceFee = 0,
    maxPlacementFee = 0,
    maxOperatingExpense = 0,
    minPlacementFee = 0,
    minOperatingExpense = 0,
    minSetupFee = 0,
    maxSetupFee = 0,
  } = selectedPlan || {};

  useEffect(() => {
    const { plan } = application || ({} as ApplicationProps);
    const {
      minCommitmentAmount = 1,
      maxCommitmentAmount = 0,
      minContributionPercentage = 1,
      currency = defaultCurrency,
      accreditedMinCommitmentAmount = 0,
      maxExitCharge = 100,
      maxHurdleRate = 100,
      maxManagementFee = 100,
      maxPerformanceFee = 100,
      minExitCharge = 0,
      minHurdleRate = 0,
      minManagementFee = 0,
      minPerformanceFee = 0,
      maxPlacementFee = 100,
      minPlacementFee = 0,
      maxOperatingExpense = 100,
      minOperatingExpense = 0,
      minSetupFee = 0,
      maxSetupFee = 100,
      minInvestmentAmt = 0,
    } = plan || {};
    setSelectedPlan(
      plan
        ? {
            ...selectedPlan,
            minCommitmentAmount: Number(minCommitmentAmount),
            maxCommitmentAmount: Number(maxCommitmentAmount),
            minContributionPercentage: Number(minContributionPercentage),
            currency,
            accreditedMinCommitmentAmount: Number(accreditedMinCommitmentAmount),
            maxExitCharge: maxExitCharge === 0 || maxExitCharge ? Number(maxExitCharge) : 100,
            maxHurdleRate: maxHurdleRate === 0 || maxHurdleRate ? Number(maxHurdleRate) : 100,
            maxManagementFee:
              maxManagementFee === 0 || maxManagementFee ? Number(maxManagementFee) : 100,
            maxPerformanceFee:
              maxPerformanceFee === 0 || maxPerformanceFee ? Number(maxPerformanceFee) : 100,
            minExitCharge: Number(minExitCharge),
            minHurdleRate: Number(minHurdleRate),
            minManagementFee: Number(minManagementFee),
            minPerformanceFee: Number(minPerformanceFee),
            maxPlacementFee:
              maxPlacementFee === 0 || maxPlacementFee ? Number(maxPlacementFee) : 100,
            minPlacementFee: Number(minPlacementFee),
            maxOperatingExpense:
              maxOperatingExpense === 0 || maxOperatingExpense ? Number(maxOperatingExpense) : 100,
            minOperatingExpense: Number(minOperatingExpense),
            maxSetupFee: maxSetupFee === 0 || maxSetupFee ? Number(maxSetupFee) : 100,
            minSetupFee: Number(minSetupFee),
            minInvestmentAmt: Number(minInvestmentAmt),
          }
        : null
    );

    setPaymentDetails({
      ...paymentDetails,
      schemeId: application?.schemeId || '',
      planId: application?.planId || '',
      commitmentAmount: application?.commitmentAmount || null,
      setupFeePercentage: application?.setupFeePercentage || 0,
      managementFeesRate: application?.managementFeesRate || 0,
      // taxRate: application?.taxRate || null,
      // drawDownNumber: application?.drawDownNumber || null,
      contributionChequeAmount: Number(application?.contributionChequeAmount) || 0,
      modeOfPayment: application?.modeOfPayment || '',
      paymentRefNo: application?.paymentRefNo || '',
      chequeDate: application?.chequeDate || null,
      paymentBankAccountNumber: application?.paymentBankAccountNumber || '',
      paymentBankame: application?.paymentBankame || '',
      paymentBankAccontType: application?.paymentBankAccontType || '',
      setupFeeAmount: application?.setupFeeAmount,
      setupFeeGST: application?.setupFeeGST,
      totalSetupFee: application?.totalSetupFee,
      setupFeeTDS: application?.setupFeeTDS,
      netSetupFeeLessTDS: application?.netSetupFeeLessTDS,
      totalAmountPayable: application?.totalAmountPayable,
      isAccreditedInvestor: application?.isAccreditedInvestor || false,
      exitChargeApplicable: application?.exitChargeApplicable || '',
      exitCharge: application?.exitCharge || 0,
      hurdleRateApplicable: application?.hurdleRateApplicable || '',
      hurdleRate: application?.hurdleRate || 0,
      performanceFeeApplicable: application?.performanceFeeApplicable || '',
      performanceFee: application?.performanceFee || 0,
      managementFeeApplicable: application?.managementFeeApplicable || '',
      managementFeeType: application?.managementFeeType || '',
      exitChargeType: application?.exitChargeType || '',
      hurdleRateType: application?.hurdleRateType || '',
      performanceFeeType: application?.performanceFeeType || '',
      placementFeeType: application?.placementFeeType || '',
      placementFeeApplicable: application?.placementFeeApplicable || '',
      placementFee: application?.placementFee || 0,
      operatingExpenseType: application?.operatingExpenseType || '',
      operatingExpenseApplicable: application?.operatingExpenseApplicable || '',
      operatingExpenses: application?.operatingExpenses || 0,
      setupFeeType: application?.setupFeeType || '',
      setupFeeApplicable: application?.setupFeeApplicable || '',
      exitLoadApplicable: application?.exitLoadApplicable || '',
      exitLoad: application?.exitLoad,
      groupInvestment: application?.groupInvestment || false,
    });
  }, [application]);

  function getUpdatedApplication(values: Values) {
    // eslint-disable-next-line
    const { saveType, ...rest } = values;
    const updatedApplication = {
      ...application,
      ...rest,
      schemeId: rest.schemeId || null,
      planId: rest.planId || null,
      contributionChequeAmount: Number(rest.contributionChequeAmount) || 0,
      setupFeePercentage: Number(rest.setupFeePercentage),
      managementFeesRate: isFieldApplicable(rest?.managementFeeApplicable || '')
        ? Number(rest.managementFeesRate)
        : null,
      hurdleRate: isFieldApplicable(rest?.hurdleRateApplicable || '')
        ? Number(rest.hurdleRate)
        : null,
      performanceFee: isFieldApplicable(rest?.performanceFeeApplicable || '')
        ? Number(rest.performanceFee)
        : null,
      exitCharge: isFieldApplicable(rest?.exitChargeApplicable || '')
        ? Number(rest.exitCharge)
        : null,
      placementFee: isFieldApplicable(rest?.placementFeeApplicable || '')
        ? Number(rest.placementFee)
        : null,
      operatingExpenses: isFieldApplicable(rest?.operatingExpenseApplicable || '')
        ? Number(rest.operatingExpenses)
        : null,
      exitLoad: isFieldApplicable(rest?.exitLoadApplicable || '') ? rest.exitLoad : '',
    };
    return updatedApplication;
  }

  const submitInvestmentData = async (values: Values, checkApplication: boolean) => {
    const {
      id,
      applicant1ReferenceId = '',
      applicationType,
      applicationNumber,
    } = application || {};
    const { saveType } = values;
    const updatedApplication = getUpdatedApplication(values);
    const isSaveLater = saveType !== 'save and proceed';
    if (id && !checkApplication) {
      setLoading(true);
      await dispatch(
        updateApplication({
          body: {
            ...updatedApplication,
            status: statusRoleBased(role, application),
            currentStep:
              applicationType === APPLICATION_TYPE.NON_INDIVIDUAL
                ? RiskProfileEnabled
                  ? getStep(5, isSaveLater)
                  : getStep(4, isSaveLater)
                : RiskProfileEnabled
                ? getStep(9, isSaveLater)
                : getStep(8, isSaveLater),
            ...((!SAVE_LATER_VALIDATION_CHECK as boolean) && { saveForLater: isSaveLater }),
          },
          applicationId: id,
          ...(isSaveLater && { toastMessage: '' }),
        })
      );
      !isSaveLater
        ? applicationType === APPLICATION_TYPE.NON_INDIVIDUAL
          ? history.push('bank-details', { id, applicant1ReferenceId })
          : history.push('document-details', { id, applicant1ReferenceId })
        : history.push(saveForLater(role, id, applicant1ReferenceId));
    } else if (checkApplication) {
      if (isSaveLater) {
        enqueueSnackbar(`Application ${applicationNumber} - ` + ' Saved successfully', {
          variant: 'success',
          autoHideDuration: 3000,
        });
      }
      !isSaveLater
        ? applicationType === APPLICATION_TYPE.NON_INDIVIDUAL
          ? history.push('bank-details', { id, applicant1ReferenceId })
          : history.push('document-details', { id, applicant1ReferenceId })
        : history.push(saveForLater(role, id, applicant1ReferenceId));
    }
  };

  const handleSubmit = async (values: Values) => {
    try {
      const { currentStep, applicationType } = application || {};
      const updatedApplication = getUpdatedApplication(values);
      const checkApplication = applicationComparison(
        { ...application, setupFeePercentage: Number(application?.setupFeePercentage) },
        {
          ...updatedApplication,
          currentStep:
            applicationType === APPLICATION_TYPE.NON_INDIVIDUAL
              ? !!currentStep && currentStep > (RiskProfileEnabled ? 4 : 3)
                ? currentStep
                : Number(currentStep) + 1
              : !!currentStep && currentStep > (RiskProfileEnabled ? 8 : 7)
              ? currentStep
              : Number(currentStep) + 1,
        }
      );
      if (values?.groupInvestment && !checkApplication) {
        setDialog({ investmentData: values, checkApplication });
      } else {
        setLoading(true);
        await submitInvestmentData(values, checkApplication);
      }
    } catch (e) {
      setLoading(false);
      console.error((e as Error).message);
    }
  };

  useEffect(() => {
    let isComponentAlive = true;
    (async function () {
      try {
        const response = application?.distributor_id
          ? ((
              (await dispatch(
                getDistributorById(application?.distributor_id)
              )) as unknown as DistributorListType
            ).planDetails.schemes as FundProps[])
          : ((await dispatch(
              getDirectApplicableFunds({ isActive: true })
            )) as unknown as FundProps[]);
        if (!isComponentAlive) {
          return;
        }
        const { scheme } = application || {};
        const updatedFundPlan =
          application?.schemeId &&
          (!application.scheme.isActive ||
            !(response.map((res) => res.id?.toString()) as unknown as string[]).includes(
              application?.schemeId?.toString()
            ))
            ? [
                ...response,
                {
                  ...application.scheme,
                  plans: [application.plan],
                } as unknown as FundProps,
              ]
            : response;
        setFundsList(updatedFundPlan);
        const updatePlans =
          application?.schemeId &&
          application?.scheme.isActive &&
          application?.planId &&
          !application.plan?.isActive
            ? ({
                ...updatedFundPlan.find((fund) => Number(fund.id) === Number(scheme?.id)),
                plans: [
                  ...(
                    updatedFundPlan.find(
                      (fund) => Number(fund.id) === Number(scheme?.id)
                    ) as unknown as FundProps
                  ).plans,
                  application.plan,
                ],
              } as unknown as FundProps)
            : updatedFundPlan.find((fund) => Number(fund.id) === Number(scheme?.id)) || null;
        setSelectedFund(updatePlans);
      } catch (e) {
        console.error((e as Error).message);
      }
    })();
    return () => {
      isComponentAlive = false;
    };
  }, [application]);

  const getClassPlanOptionsOrDetails = (isAccreditedInvestor: boolean, detail?: string) => {
    if (detail) {
      return selectedFund?.[detail] || '';
    }

    const plansCombinedWithApplicationPlan = selectedFund?.plans?.some(
      (plan) => Number(plan.id) === Number(application?.planId)
    )
      ? selectedFund?.plans
      : application?.schemeId?.toString() === selectedFund?.id?.toString()
      ? selectedFund?.plans?.concat(application?.plan as unknown as ClassPlanProps)
      : selectedFund?.plans;
    const filteredPlans = (
      isAccreditedInvestor
        ? plansCombinedWithApplicationPlan?.filter((plan) =>
            isAccreditedInvestorApplicable(plan.accreditedInvestorApplicable)
          )
        : plansCombinedWithApplicationPlan
    )?.filter((ele) => ele);
    return (
      filteredPlans?.map((plan) => ({
        key: plan?.planDescription,
        value: Number(plan?.id),
      })) || []
    );
  };

  useEffect(() => {
    (async function () {
      try {
        const jointHodersMdmsMasters = (await dispatch(
          jointHolderRelationsList()
        )) as unknown as JointholderRelationMaster;
        setJointHoldersData(jointHodersMdmsMasters);
      } catch (e) {
        console.error((e as Error).message);
      }
    })();
  }, []);

  const isFieldDisabled = [USER_ROLES.INVESTOR, USER_ROLES.POAAPPROVER].includes(role);
  return (
    <Formik
      initialValues={paymentDetails}
      onSubmit={handleSubmit}
      enableReinitialize={true}
      validate={(values: Values) => {
        const minCommitmentAmountBasedOnJointHolders =
          application && application.applicants?.length > 1 && jointHoldersData
            ? (updatedCommitmentAmount(
                Number(minCommitmentAmount),
                application.applicants,
                jointHoldersData
              ) as number)
            : minCommitmentAmount;
        try {
          validateYupSchema(
            values,
            investmentPaymentDetailsSchema(
              (values?.groupInvestment
                ? selectedPlan?.minInvestmentAmt
                : values?.isAccreditedInvestor
                ? selectedPlan?.accreditedMinCommitmentAmount || 0
                : minCommitmentAmountBasedOnJointHolders) as number,
              maxCommitmentAmount || 0,
              minContributionPercentage || 0,
              currency || '',
              values?.saveType,
              maxExitCharge === 0 || maxExitCharge ? Number(maxExitCharge) : 100,
              maxHurdleRate === 0 || maxHurdleRate ? Number(maxHurdleRate) : 100,
              maxManagementFee === 0 || maxManagementFee ? Number(maxManagementFee) : 100,
              maxPerformanceFee === 0 || maxPerformanceFee ? Number(maxPerformanceFee) : 100,
              maxPlacementFee === 0 || maxPlacementFee ? Number(maxPlacementFee) : 100,
              maxOperatingExpense === 0 || maxOperatingExpense ? Number(maxOperatingExpense) : 100,
              maxSetupFee === 0 || maxSetupFee ? Number(maxSetupFee) : 100,
              minExitCharge,
              minHurdleRate,
              minManagementFee,
              minPerformanceFee,
              minPlacementFee,
              minOperatingExpense,
              minSetupFee
            ),
            true,
            values
          );
        } catch (e) {
          return yupToFormErrors(e);
        }
      }}>
      {({ handleSubmit, values, setValues }) => (
        <Grid
          container
          rowSpacing={1}
          columnSpacing={5}
          component="form"
          noValidate
          onSubmit={handleSubmit}
          sx={{
            width: '100%',
            ml: 0,
            '.MuiGrid-item': { px: { xs: 0, sm: '30px' } },
          }}>
          <Grid item xs={12}>
            <MFCheckbox
              name={`isAccreditedInvestor`}
              label="Accredited Investor"
              sx={{ mt: 1 }}
              onChange={({ target: { checked } }) => {
                try {
                  if (values.groupInvestment) {
                    throw 'Uncheck Lower the Commitment Amount to select Accredited Investor';
                  } else {
                    setValues({
                      ...values,
                      ...fundInitialValues,
                      isAccreditedInvestor: checked,
                    });
                  }
                  setSelectedFund(null);
                  setSelectedPlan(null);
                } catch (e) {
                  typeof e === 'string' && dispatch(showError(e));
                }
              }}
              disabled={isFieldDisabled}
            />
          </Grid>
          <Grid item xs={12}>
            <MFCheckbox
              name={`groupInvestment`}
              label="Lower The Commitment Amount"
              sx={{ mt: 1 }}
              disabled={![USER_ROLES.RM, USER_ROLES.AMC_ADMIN].includes(role)}
              onChange={({ target: { checked } }) => {
                try {
                  if (values.isAccreditedInvestor) {
                    throw 'Uncheck Accredited Investor to select Lower the Commitment Amount';
                  } else {
                    setValues({
                      ...values,
                      groupInvestment: checked,
                    });
                  }
                } catch (e) {
                  typeof e === 'string' && dispatch(showError(e));
                }
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <SearchableSelect
              name={'schemeId'}
              label="Fund (Scheme) *"
              items={fundsList.map((fund) => ({ value: Number(fund.id), key: fund.schemeName }))}
              onChange={({ target: { value } }: SelectChangeEvent<unknown>) => {
                setValues({
                  ...values,
                  ...fundInitialValues,
                  schemeId: value as string,
                  setupFeeAmount: 0,
                  setupFeeGST: 0,
                  totalSetupFee: 0,
                  setupFeeTDS: 0,
                  netSetupFeeLessTDS: 0,
                  isAccreditedInvestor: values.isAccreditedInvestor,
                  totalAmountPayable: 0,
                  groupInvestment: values.groupInvestment,
                });
                setSelectedFund(fundsList.find((f) => Number(f.id) === value) || null);
                setSelectedPlan(null);
              }}
              disabled={isFieldDisabled}
              searchFieldPlaceholder={'Search Fund (Scheme)'}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <SearchableSelect
              name={'planId'}
              label="Class (Plan) *"
              items={
                getClassPlanOptionsOrDetails(values.isAccreditedInvestor as boolean) as [
                  { key: string; value: string | number }
                ]
              }
              disabled={!values.schemeId || isFieldDisabled}
              onChange={({ target: { value } }: SelectChangeEvent<unknown>) => {
                const selectedPlan = selectedFund?.plans
                  ?.filter((plan) => plan.isActive)
                  ?.find((p) => Number(p.id) === value);
                setSelectedPlan(selectedPlan || null);
                setValues({
                  ...values,
                  planId: value as string,
                  commitmentAmount: 0,
                  contributionChequeAmount: 0,
                  setupFeePercentage: selectedPlan?.setupFee,
                  managementFeesRate: selectedPlan?.defaultManagementFee,
                  setupFeeAmount: 0,
                  setupFeeGST: 0,
                  totalSetupFee: 0,
                  setupFeeTDS: 0,
                  netSetupFeeLessTDS: 0,
                  totalAmountPayable: 0,
                  hurdleRateApplicable: selectedPlan?.hurdleRateApplicable,
                  hurdleRate: selectedPlan?.defaultHurdleRate || 0,
                  performanceFeeApplicable: selectedPlan?.performanceFeeApplicable,
                  performanceFee: selectedPlan?.defaultPerformanceFee || 0,
                  exitChargeApplicable: selectedPlan?.exitChargeApplicable,
                  exitCharge: selectedPlan?.defaultExitCharge || 0,
                  managementFeeApplicable: selectedPlan?.managementFeeApplicable,
                  managementFeeType: selectedPlan?.managementFeeType,
                  hurdleRateType: selectedPlan?.hurdleRateType,
                  exitChargeType: selectedPlan?.exitChargeType,
                  performanceFeeType: selectedPlan?.performanceFeeType,
                  placementFee: selectedPlan?.defaultPlacementFee || 0,
                  placementFeeApplicable: selectedPlan?.placementFeeApplicable,
                  placementFeeType: selectedPlan?.placementFeeType,
                  operatingExpenses: selectedPlan?.defaultOperatingExpenses || 0,
                  operatingExpenseApplicable: selectedPlan?.operatingExpenseApplicable,
                  operatingExpenseType: selectedPlan?.operatingExpenseType,
                  setupFeeApplicable: selectedPlan?.setupFeeApplicable,
                  setupFeeType: selectedPlan?.setupFeeType,
                  exitLoadApplicable: selectedPlan?.exitLoadApplicable,
                  exitLoad: selectedPlan?.exitLoad,
                });
              }}
              searchFieldPlaceholder={'Search Class (Plan)'}
            />
          </Grid>
          <Grid item xs={12} sm={6} sx={{ '.MuiFormControl-root': { marginBottom: '2px' } }}>
            <MFTextField
              name={'commitmentAmount'}
              label="Commitment Amount *"
              type="number"
              placeholder="Enter Commitment Amount"
              onKeyDown={(e) => {
                allowOnlyNumbers(e);
              }}
              onChange={({ target: { value } }: React.ChangeEvent<HTMLInputElement>) => {
                const feeAmountObj = setUpFeeCalculations(
                  Number(value) || 0,
                  values.setupFeePercentage || 0
                );
                const netSetupFee = feeAmountObj.totalSetupFee - (values.setupFeeTDS || 0);
                setValues({
                  ...values,
                  commitmentAmount: value ? Number(value) : null,
                  setupFeeAmount: feeAmountObj.feeAmount,
                  setupFeeGST: feeAmountObj.feeGst,
                  totalSetupFee: feeAmountObj.totalSetupFee,
                  netSetupFeeLessTDS: netSetupFee,
                  totalAmountPayable: values.contributionChequeAmount || Number(value),
                });
              }}
              trimOnBlur={false}
              disabled={isFieldDisabled || !values.planId}
            />
            {values.commitmentAmount != 0 && (
              <Typography sx={{ fontSize: 13 }}>
                {currencyConversion(values.commitmentAmount, selectedPlan?.currency)}
              </Typography>
            )}
          </Grid>
          {isFieldApplicable(values?.managementFeeApplicable || '') && (
            <Grid item xs={12} sm={6}>
              <MFTextField
                name={'managementFeesRate'}
                label="Management Fees Rate *"
                placeholder="Enter Management Fee Percentage"
                type="number"
                onKeyDown={(e) => {
                  allowOnlyNumbers(e);
                }}
                trimOnBlur={false}
                disabled={
                  !isFieldVariable(
                    values?.managementFeeType || '',
                    values?.managementFeeApplicable || ''
                  ) ||
                  isFieldDisabled ||
                  !values.planId
                }
              />
            </Grid>
          )}
          {isFieldApplicable(values?.placementFeeApplicable || '') && (
            <Grid item xs={12} sm={6}>
              <MFTextField
                name={'placementFee'}
                label="Placement Fees Rate *"
                placeholder="Enter Placement Fee Percentage"
                type="number"
                onKeyDown={(e) => {
                  allowOnlyNumbers(e);
                }}
                trimOnBlur={false}
                disabled={
                  !isFieldVariable(
                    values?.placementFeeType || '',
                    values?.placementFeeApplicable || ''
                  ) ||
                  isFieldDisabled ||
                  !values.planId
                }
              />
            </Grid>
          )}

          {isFieldApplicable(values?.operatingExpenseApplicable || '') && (
            <Grid item xs={12} sm={6}>
              <MFTextField
                name={'operatingExpenses'}
                label="Operating Expense Rate *"
                placeholder="Enter Operating Expense Percentage"
                type="number"
                onKeyDown={(e) => {
                  allowOnlyNumbers(e);
                }}
                trimOnBlur={false}
                disabled={
                  !isFieldVariable(
                    values?.operatingExpenseType || '',
                    values?.operatingExpenseApplicable || ''
                  ) ||
                  isFieldDisabled ||
                  !values.planId
                }
              />
            </Grid>
          )}
          {/* <Grid item xs={12} sm={6}>
            <MFTextField
              name={'taxRate'}
              label="Tax Rate"
              placeholder="Enter Tax Rate"
              type="number"
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <MFSelectField
              name={'drawDownNumber'}
              label="Drawdown Number"
              items={DrawDownMasters.map((master) => ({ key: master, value: master }))}
            />
          </Grid> */}
          <Grid item xs={12} sm={6} sx={{ '.MuiFormControl-root': { marginBottom: '2px' } }}>
            <MFTextField
              name={'contributionChequeAmount'}
              label="Contribution Amount *"
              placeholder="Enter Contribution Amount"
              type="number"
              onKeyDown={(e) => {
                allowOnlyNumbers(e);
              }}
              onChange={({ target: { value } }: React.ChangeEvent<HTMLInputElement>) => {
                const feeAmountObj = setUpFeeCalculations(
                  values.commitmentAmount || 0,
                  values.setupFeePercentage || 0
                );
                const netSetupFee = feeAmountObj.totalSetupFee - (values.setupFeeTDS || 0);
                setValues({
                  ...values,
                  contributionChequeAmount: value ? Number(value) : null,
                  setupFeeAmount: feeAmountObj.feeAmount,
                  setupFeeGST: feeAmountObj.feeGst,
                  totalSetupFee: feeAmountObj.totalSetupFee,
                  netSetupFeeLessTDS: netSetupFee,
                  totalAmountPayable: Number(value) || values.commitmentAmount,
                });
              }}
              trimOnBlur={false}
              disabled={isFieldDisabled || !values.planId}
            />
            {values.contributionChequeAmount != 0 && (
              <Typography sx={{ fontSize: 13 }}>
                {currencyConversion(values.contributionChequeAmount, selectedPlan?.currency)}
              </Typography>
            )}
          </Grid>
          {isFieldApplicable(values?.setupFeeApplicable || '') && (
            <>
              <Grid item xs={12} sm={6}>
                <MFTextField
                  name={'setupFeePercentage'}
                  label="Setup Fee Percentage *"
                  type="number"
                  placeholder="Enter Setup Fee Percentage"
                  onKeyDown={(e) => {
                    allowOnlyNumbers(e);
                  }}
                  onChange={({ target: { value } }: React.ChangeEvent<HTMLInputElement>) => {
                    const feeAmountObj = setUpFeeCalculations(
                      values.commitmentAmount || 0,
                      Number(value)
                    );
                    const netSetupFee = feeAmountObj.totalSetupFee - (values.setupFeeTDS || 0);
                    setValues({
                      ...values,
                      setupFeePercentage: value ? Number(value) : null,
                      setupFeeAmount: feeAmountObj.feeAmount,
                      setupFeeGST: feeAmountObj.feeGst,
                      totalSetupFee: feeAmountObj.totalSetupFee,
                      netSetupFeeLessTDS: netSetupFee,
                      totalAmountPayable:
                        values.contributionChequeAmount || values.commitmentAmount,
                    });
                  }}
                  trimOnBlur={false}
                  disabled={
                    isFieldDisabled ||
                    !isFieldVariable(
                      values?.setupFeeType || '',
                      values?.setupFeeApplicable || ''
                    ) ||
                    !values.planId
                  }
                />
              </Grid>
              <Grid item xs={12} sm={6} sx={{ '.MuiFormControl-root': { marginBottom: '2px' } }}>
                <MFTextField
                  name={'setupFeeAmount'}
                  label="Setup Fee Amount"
                  type="number"
                  placeholder="Enter Setup Fee Amount"
                  onKeyDown={(e) => {
                    allowOnlyNumbers(e);
                  }}
                  trimOnBlur={false}
                  disabled={true}
                />
                {values.setupFeeAmount != 0 && (
                  <Typography sx={{ fontSize: 13 }}>
                    {currencyConversion(values.setupFeeAmount, selectedPlan?.currency)}
                  </Typography>
                )}
              </Grid>
              <Grid item xs={12} sm={6} sx={{ '.MuiFormControl-root': { marginBottom: '2px' } }}>
                <MFTextField
                  name={'setupFeeGST'}
                  label="GST (GST On Setup Fee Amount)"
                  type="number"
                  placeholder="Enter GST"
                  onKeyDown={(e) => {
                    allowOnlyNumbers(e);
                  }}
                  trimOnBlur={false}
                  disabled={true}
                />
                {values.setupFeeGST != 0 && (
                  <Typography sx={{ fontSize: 13 }}>
                    {currencyConversion(values.setupFeeGST, selectedPlan?.currency)}
                  </Typography>
                )}
              </Grid>
              <Grid item xs={12} sm={6} sx={{ '.MuiFormControl-root': { marginBottom: '2px' } }}>
                <MFTextField
                  name={'totalSetupFee'}
                  label="Total Setup Fee"
                  type="number"
                  placeholder="Enter Total Setup Fee"
                  onKeyDown={(e) => {
                    allowOnlyNumbers(e);
                  }}
                  trimOnBlur={false}
                  disabled={true}
                />
                {values.totalSetupFee != 0 && (
                  <Typography sx={{ fontSize: 13 }}>
                    {currencyConversion(values.totalSetupFee, selectedPlan?.currency)}
                  </Typography>
                )}
              </Grid>
              <Grid item xs={12} sm={6} sx={{ '.MuiFormControl-root': { marginBottom: '2px' } }}>
                <MFTextField
                  name={'setupFeeTDS'}
                  label="TDS"
                  type="number"
                  placeholder="Enter TDS"
                  onKeyDown={(e) => {
                    allowOnlyNumbers(e);
                  }}
                  onChange={({ target: { value } }: React.ChangeEvent<HTMLInputElement>) => {
                    const setupFeeLessTDS = Number(values.totalSetupFee || 0) - Number(value);
                    setValues({
                      ...values,
                      setupFeeTDS: value ? Number(value) : null,
                      netSetupFeeLessTDS: setupFeeLessTDS,
                      totalAmountPayable:
                        values.contributionChequeAmount || values.commitmentAmount,
                    });
                  }}
                  trimOnBlur={false}
                  disabled={isFieldDisabled || !values.planId}
                />
                {values.setupFeeTDS != 0 && (
                  <Typography sx={{ fontSize: 13 }}>
                    {currencyConversion(values.setupFeeTDS, selectedPlan?.currency)}
                  </Typography>
                )}
              </Grid>
              <Grid item xs={12} sm={6} sx={{ '.MuiFormControl-root': { marginBottom: '2px' } }}>
                <MFTextField
                  name={'netSetupFeeLessTDS'}
                  label="Setup Fee Payable"
                  type="number"
                  placeholder="Enter Setup Fee Payable"
                  onKeyDown={(e) => {
                    allowOnlyNumbers(e);
                  }}
                  trimOnBlur={false}
                  disabled={true}
                />
                {values.netSetupFeeLessTDS != 0 && (
                  <Typography sx={{ fontSize: 13 }}>
                    {currencyConversion(values.netSetupFeeLessTDS, selectedPlan?.currency)}
                  </Typography>
                )}
              </Grid>
            </>
          )}
          {isFieldApplicable(values?.hurdleRateApplicable || '') && (
            <Grid item xs={12} sm={6}>
              <MFTextField
                name={'hurdleRate'}
                label="Hurdle Rate *"
                placeholder="Enter Hurdle Rate"
                type="number"
                onKeyDown={(e) => {
                  allowOnlyNumbers(e);
                }}
                trimOnBlur={false}
                disabled={
                  !isFieldVariable(
                    values?.hurdleRateType || '',
                    values?.hurdleRateApplicable || ''
                  ) ||
                  isFieldDisabled ||
                  !values.planId
                }
              />
            </Grid>
          )}
          {isFieldApplicable(values?.performanceFeeApplicable || '') && (
            <Grid item xs={12} sm={6}>
              <MFTextField
                name={'performanceFee'}
                label="Performance Fee *"
                placeholder="Enter Performance Fee"
                type="number"
                onKeyDown={(e) => {
                  allowOnlyNumbers(e);
                }}
                trimOnBlur={false}
                disabled={
                  !isFieldVariable(
                    values?.performanceFeeType || '',
                    values?.performanceFeeApplicable || ''
                  ) ||
                  isFieldDisabled ||
                  !values.planId
                }
              />
            </Grid>
          )}
          {isFieldApplicable(values?.exitChargeApplicable || '') && (
            <Grid item xs={12} sm={6}>
              <MFTextField
                name={'exitCharge'}
                label="Exit Charge *"
                placeholder="Enter Exit Charge"
                type="number"
                onKeyDown={(e) => {
                  allowOnlyNumbers(e);
                }}
                trimOnBlur={false}
                disabled={
                  !isFieldVariable(
                    values?.exitChargeType || '',
                    values?.exitChargeApplicable || ''
                  ) ||
                  isFieldDisabled ||
                  !values.planId
                }
              />
            </Grid>
          )}
          <Grid item xs={12} sm={6} sx={{ '.MuiFormControl-root': { marginBottom: '2px' } }}>
            <MFTextField
              name={'totalAmountPayable'}
              label="Total Amount Payable"
              type="number"
              placeholder="Enter Total Amount Payable"
              onKeyDown={(e) => {
                allowOnlyNumbers(e);
              }}
              trimOnBlur={false}
              disabled={true}
            />
            {values.totalAmountPayable != 0 && (
              <Typography sx={{ fontSize: 13 }}>
                {currencyConversion(values.totalAmountPayable, selectedPlan?.currency)}
              </Typography>
            )}
          </Grid>
          {isFieldApplicable(values.exitLoadApplicable || '') && (
            <Grid item xs={12} sm={6} sx={{ pl: { xs: 0, sm: '28px' }, mt: { xs: 2, sm: 0 } }}>
              <MFTextField
                name="exitLoad"
                multiline
                rows={4.5}
                label={`Exit Load *`}
                placeholder={`Enter Exit Load`}
                disabled={true}
              />
            </Grid>
          )}
          {/* <Grid
            item
            xs={12}
            onClick={() => setPaymentOpen(!paymentOpen)}
            sx={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              cursor: 'pointer',
              my: 2,
            }}>
            <Typography
              component="h3"
              sx={{
                fontFamily: 'Poppins, sans-serif',
                fontSize: 25,
                fontWeight: 500,
                color: 'secondary.main',
              }}>
              Payment Details
            </Typography>
            <ExpandMoreIcon sx={{ fontSize: 30, transform: paymentOpen ? 'rotate(180deg)' : '' }} />
          </Grid> */}
          {/* {paymentOpen && (
            <>
              <Grid item xs={12} sm={6}>
                <MFTextField
                  name={'paymentBankame'}
                  label="Payment Bank Name"
                  placeholder="Enter Payment Bank Name"
                  disabled={isFieldDisabled}
                />
              </Grid>
              <Grid item xs={12} sm={6} />
              <Grid item xs={12} sm={6}>
                <MFTextField
                  name={'paymentBankAccountNumber'}
                  label="Payment Bank Account Number"
                  placeholder="Enter Payment Bank Account Number"
                  disabled={isFieldDisabled}
                />
              </Grid>
              <Grid item xs={12} sm={6} sx={{ pt: '25px !important' }}>
                <UseRadioGroup
                  name={'paymentBankAccontType'}
                  formLabel="Payment Bank Account Type"
                  defaultValue="current"
                  items={[
                    { label: 'Current', value: 'current' },
                    { label: 'Savings', value: 'savings' },
                  ]}
                  disabled={isFieldDisabled}
                />
              </Grid>
              <Grid item xs={12}>
                <UseRadioGroup
                  name={'modeOfPayment'}
                  formLabel="Mode of Payment"
                  defaultValue="cheque"
                  items={[
                    { label: 'Cheque', value: 'cheque' },
                    { label: 'RTGS', value: 'rtgs' },
                    { label: 'Online Transfer', value: 'online-transfer' },
                    { label: 'Others', value: 'others' },
                  ]}
                  disabled={isFieldDisabled}
                />
              </Grid>
              {values.modeOfPayment === 'cheque' && (
                <Grid item xs={12} sm={6}>
                  <DatePicker
                    label={'Payment/Cheque Date'}
                    placeholder={'Enter Cheque Date'}
                    inputLabelStyles={{
                      transform: 'unset',
                      fontSize: 14,
                      fontWeight: 500,
                      color: 'rgba(0,0,0,0.7)',
                    }}
                    name="chequeDate"
                    disabled={isFieldDisabled}
                  />
                </Grid>
              )}
              <Grid item xs={12} sm={6}>
                <MFTextField
                  name={'paymentRefNo'}
                  label="Payment Ref No"
                  placeholder="Enter Payment Ref No"
                  disabled={isFieldDisabled}
                />
              </Grid>
            </>
          )} */}
          <FieldValidationNote />
          <ProceedSaveLater
            saveLater={() => {
              setValues({
                ...values,
                saveType: 'save for later',
              });
            }}
            saveAndProceed={() => {
              setValues({
                ...values,
                saveType: 'save and proceed',
              });
            }}
            loader={loading}
            clickedButton={values.saveType}
          />
          <ConfirmationDialog
            message={`Are you sure you want to proceed with Lower The Commitment Amount Option ?`}
            open={openDialog?.investmentData?.groupInvestment as boolean}
            setOpen={() => setDialog(null)}
            onSave={async () => {
              setDialog(null);
              try {
                setLoading(true);
                await submitInvestmentData(
                  openDialog?.investmentData as Values,
                  openDialog?.checkApplication as boolean
                );
              } catch (e) {
                setLoading(false);
                console.error((e as Error).message);
              }
            }}
            onCancel={() => setDialog(null)}
            displayInfo={
              Number(openDialog?.investmentData.commitmentAmount) > Number(maxCommitmentAmount)
                ? 'Commitment amount does not match with provided class of units'
                : ''
            }
          />
        </Grid>
      )}
    </Formik>
  );
}
