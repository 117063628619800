import { Typography } from '@mui/material';
import React from 'react';
import { Item } from '../Investments/investor-application';

export default function ExitLoad({ exitLoad }: { exitLoad?: string }): JSX.Element {
  return (
    <>
      <Item
        sx={{
          fontSize: 15,
          fontWeight: 500,
          color: 'rgba(0, 0, 0, 0.6)',
          mb: 1,
          fontFamily: 'Work Sans,sans-serif',
        }}>
        Exit Load
      </Item>
      {exitLoad ? (
        <Typography
          sx={{
            fontSize: 16,
            fontWeight: 700,
            color: 'rgba(0, 0, 0, 0.6)',
            mb: 1,
            fontFamily: 'Work Sans,sans-serif',
          }}>
          {exitLoad}
        </Typography>
      ) : (
        '-'
      )}
    </>
  );
}
